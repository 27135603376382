import { graphql } from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import { GatsbyImage } from 'gatsby-plugin-image'
import get from 'lodash.get'
import React from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import * as Icon from 'react-feather'
import Masonry from 'react-masonry-css'
import Scrollspy from 'react-scrollspy'
import styled from 'styled-components'
import HTMLBlock from '../components/html-content'
import Layout from '../components/layout'
import Responsive, { FlexCol, FlexRow, ResponsiveImage } from '../components/responsive'
import Seo from '../components/seo'
import { LeadParagraph } from '../components/text'
import Title from '../components/titles'
import colors from '../theme/colors'
import media, { breakpoints } from '../theme/media-queries'
const QuiSommesNous = ({ data, uri }) => {
    const { document_title, document_description, body } = get(data, 'prismicWhoAreWe.data')
    const slices = body.map(({ slice_type, primary, items }) => {
        switch (slice_type) {
            case 'vision': {
                const { id, vision_title, vision_baseline, vision_content, vision_image } = primary
                return {
                    id,
                    type: slice_type,
                    title: get(vision_title, 'text'),
                    baseline: get(vision_baseline, 'text'),
                    content: get(vision_content, 'html'),
                    image: get(vision_image, 'localFile.childImageSharp.gatsbyImageData'),
                }
            }
            case 'mission': {
                const { id, mission_title, mission_baseline, mission_content, mission_image } = primary
                return {
                    id,
                    type: slice_type,
                    title: get(mission_title, 'text'),
                    baseline: get(mission_baseline, 'text'),
                    content: get(mission_content, 'html'),
                    image: get(mission_image, 'localFile.childImageSharp.gatsbyImageData'),
                }
            }
            case 'engagement': {
                const { id, engagement_title, engagement_baseline } = primary
                return {
                    id,
                    type: slice_type,
                    title: get(engagement_title, 'text'),
                    baseline: get(engagement_baseline, 'text'),
                    engagements: items.map(({ engagement_point_title, engagement_point_content, engagement_point_icon }) => {
                        return {
                            title: get(engagement_point_title, 'text'),
                            content: get(engagement_point_content, 'html'),
                            EngagementIcon: Icon[engagement_point_icon],
                        }
                    }),
                }
            }
            case 'chiffres': {
                const { id, chiffres_title, chiffres_baseline, chiffres_content } = primary
                return {
                    id,
                    type: slice_type,
                    title: get(chiffres_title, 'text'),
                    baseline: get(chiffres_baseline, 'text'),
                    content: get(chiffres_content, 'html'),
                    kpis: items.map(({ chiffres_kpi_title, chiffres_kpi_value }) => {
                        return {
                            title: chiffres_kpi_title,
                            value: chiffres_kpi_value,
                        }
                    }),
                }
            }
            case 'team': {
                const { title, baseline, id } = primary
                return {
                    id,
                    type: slice_type,
                    title: get(title, 'text'),
                    baseline: get(baseline, 'text'),
                    teamMates: items.map(({ people_name, job_title, fun_fact, people_picture }) => {
                        return {
                            fullName: people_name,
                            jobTitle: job_title,
                            funFact: fun_fact,
                            image: get(people_picture, 'localFile.childImageSharp.gatsbyImageData'),
                        }
                    }),
                }
            }
            case 'alliance': {
                const { id, alliance_title, alliance_baseline, alliance_content } = primary
                return {
                    id,
                    type: slice_type,
                    title: get(alliance_title, 'text'),
                    baseline: get(alliance_baseline, 'text'),
                    content: get(alliance_content, 'html'),
                    enterprises: items.map(
                        ({ alliance_enterprise_name, alliance_enteprise_logo, alliance_enterprise_website }) => {
                            return {
                                name: get(alliance_enterprise_name, 'text'),
                                url: get(alliance_enterprise_website, 'url'),
                                image: get(alliance_enteprise_logo, 'localFile.childImageSharp.gatsbyImageData'),
                            }
                        }
                    ),
                }
            }
            default:
                return null
        }
    })

    const tabs = slices.map((slice) => {
        const { id, title } = slice
        return {
            id,
            title,
        }
    })

    return (
        <Layout uri={uri} withMiniHeader>
            <Seo title={get(document_title, 'text')} description={document_description} />

            <TabNav>
                <Responsive>
                    <Tabs items={tabs.map((tab) => tab.id)} currentClassName="active" offset={-170}>
                        {tabs.map(({ id, title }, index) => (
                            <Tab key={index}>
                                <AnchorLink offset={100} href={`#${id}`}>
                                    <Title>{title}</Title>
                                </AnchorLink>
                            </Tab>
                        ))}
                    </Tabs>
                </Responsive>
            </TabNav>

            {slices.map((slice, index) => {
                switch (slice.type) {
                    case 'vision':
                        return <VisionSlice {...slice} key={index} />
                    case 'mission':
                        return <MissionSlice {...slice} key={index} />
                    case 'engagement':
                        return <EngagementSlice {...slice} key={index} />
                    case 'chiffres':
                        return <ChiffresSlice {...slice} key={index} />
                    case 'team':
                        return <TeamSlice {...slice} key={index} />
                    case 'alliance':
                        return <AllianceSlice {...slice} key={index} />
                    default:
                        return null
                }
            })}
        </Layout>
    )
}

const VisionSlice = ({ id, title, baseline, content, image }) => {
    return (
        <Section id={id}>
            <Responsive>
                <FlexRow collapseTablet>
                    <FlexCol flex={2}>
                        <Title size="hero" as="h2" withDot>
                            {title}
                        </Title>
                        {!!baseline && <Baseline>{baseline}</Baseline>}
                        <HTMLBlock>{content}</HTMLBlock>
                    </FlexCol>
                    <FlexCol flex={1}>
                        <VisionImage borderRadius="50%">
                            <GatsbyImage image={image} alt="" />
                        </VisionImage>
                    </FlexCol>
                </FlexRow>
            </Responsive>
        </Section>
    )
}

const MissionSlice = ({ id, title, baseline, content, image }) => {
    return (
        <Mission id={id} background={colors.beige}>
            <Responsive>
                <FlexRow collapseTablet>
                    <FlexCol flex={4} style={{ position: 'relative' }}>
                        <MissionBackground>
                            <ResponsiveImage borderRadius="2rem">
                                <GatsbyImage image={image} alt="" />
                            </ResponsiveImage>
                        </MissionBackground>
                    </FlexCol>
                    <FlexCol flex={8}>
                        <Title size="hero" as="h2" withDot>
                            {title}
                        </Title>
                        {!!baseline && <Baseline>{baseline}</Baseline>}
                        <HTMLBlock>{content}</HTMLBlock>
                    </FlexCol>
                </FlexRow>
            </Responsive>
        </Mission>
    )
}

const EngagementSlice = ({ id, title, baseline, engagements }) => {
    const engagementsRows = engagements.reduce((acc, e, index) => {
        index % 3 === 0 && acc.push([])
        acc[acc.length - 1].push(e)
        return acc
    }, [])

    return (
        <Section id={id}>
            <Responsive>
                <Title size="hero" as="h2" withDot>
                    {title}
                </Title>
                {!!baseline && <Baseline>{baseline}</Baseline>}
                {engagementsRows.map((engagements, index) => (
                    <FlexRow align="stretch" collapseTablet key={index}>
                        {engagements.map((engagement, index) => {
                            return (
                                <FlexCol flexGrow={0} flexShrink={0} flexBasis="32%" key={index}>
                                    <Engagement {...engagement} />
                                </FlexCol>
                            )
                        })}
                    </FlexRow>
                ))}
            </Responsive>
        </Section>
    )
}

const Engagement = ({ title, content, EngagementIcon }) => {
    return (
        <EngagementContainer>
            <FlexRow align="flex-start" gutter="1rem">
                <FlexCol flexGrow="0" flexBasis="auto">
                    <CircleIcon>
                        <EngagementIcon size={32} color="aliceblue" />
                    </CircleIcon>
                </FlexCol>
                <FlexCol>
                    <EngagementTitle size="large" as="h3">
                        {title}
                    </EngagementTitle>
                    <HTMLBlock>{content}</HTMLBlock>
                </FlexCol>
            </FlexRow>
        </EngagementContainer>
    )
}

const ChiffresSlice = ({ id, title, baseline, content, kpis }) => {
    return (
        <Chiffres id={id}>
            <Responsive>
                <FlexRow collapseTablet>
                    <FlexCol flex={1}>
                        <Title over="light" size="hero" as="h2" withDot>
                            {title}
                        </Title>
                        {!!baseline && <Baseline>{baseline}</Baseline>}
                        <HTMLBlock>{content}</HTMLBlock>
                    </FlexCol>
                    <FlexCol flex={2}>
                        <KeyNumbers>
                            {kpis.map(({ title, value }, index) => (
                                <KeyNumber key={index}>
                                    <Value>{value}</Value>
                                    <Legend>{title}</Legend>
                                </KeyNumber>
                            ))}
                        </KeyNumbers>
                    </FlexCol>
                </FlexRow>
            </Responsive>
        </Chiffres>
    )
}

const TeamSlice = ({ id, title, baseline, teamMates }) => {
    return (
        <Section id={id}>
            <Responsive>
                <Title size="hero" as="h2" withDot>
                    {title}
                </Title>
                {!!baseline && <Baseline>{baseline}</Baseline>}
                <TeamMatesGrid
                    breakpointCols={{
                        default: 4,
                        [breakpoints.large]: 3,
                        [breakpoints.tabletPortrait]: 3,
                        [breakpoints.small]: 2,
                    }}
                    columnClassName="column">
                    {teamMates.map((teamMate, index) => {
                        return <TeamMate {...teamMate} key={index} />
                    })}
                </TeamMatesGrid>
            </Responsive>
        </Section>
    )
}

const TeamMate = ({ fullName, funFact, image }) => {
    return (
        <TeamMateContainer>
            <GatsbyImage image={image} alt={fullName} title={funFact} />
            <Caption as="figcaption" bold>
                {fullName}
            </Caption>
        </TeamMateContainer>
    )
}

const AllianceSlice = ({ id, title, baseline, content, enterprises }) => {
    return (
        <Alliance id={id}>
            <Responsive>
                <Title size="hero" as="h2" withDot>
                    {title}
                </Title>
                {!!baseline && <Baseline>{baseline}</Baseline>}
                <FlexRow collapseTablet gutter="4rem" align="flex-start">
                    <FlexCol flex={3}>
                        <HTMLBlock>{content}</HTMLBlock>
                    </FlexCol>
                    <FlexCol flex={2}>
                        <AllianceEntreprises
                            breakpointCols={{
                                default: 2,
                                [breakpoints.large]: 2,
                                [breakpoints.tabletPortrait]: 3,
                                [breakpoints.small]: 2,
                            }}
                            columnClassName="column">
                            {enterprises.map(({ name, url, image }, index) => (
                                <OutboundLink href={url} target="_blank" rel="noopener noreferrer" key={index}>
                                    <ResponsiveImage borderRadius="0.5rem">
                                        <GatsbyImage image={image} alt={name} title={name} />
                                    </ResponsiveImage>
                                </OutboundLink>
                            ))}
                        </AllianceEntreprises>
                    </FlexCol>
                </FlexRow>
            </Responsive>
        </Alliance>
    )
}

export const query = graphql`
    query WhoAreWe {
        prismicWhoAreWe {
            data {
                body {
                    ... on PrismicWhoAreWeBodyVision {
                        slice_type
                        primary {
                            id
                            vision_title {
                                text
                            }
                            vision_baseline {
                                text
                            }
                            vision_content {
                                html
                            }
                            vision_image {
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(layout: CONSTRAINED, width: 800, height: 800, placeholder: TRACED_SVG)
                                    }
                                }
                            }
                        }
                    }
                    ... on PrismicWhoAreWeBodyMission {
                        slice_type
                        primary {
                            id
                            mission_title {
                                text
                            }
                            mission_baseline {
                                text
                            }
                            mission_content {
                                html
                            }
                            mission_image {
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(layout: CONSTRAINED, width: 800, height: 800, placeholder: TRACED_SVG)
                                    }
                                }
                            }
                        }
                    }
                    ... on PrismicWhoAreWeBodyEngagement {
                        slice_type
                        primary {
                            id
                            engagement_title {
                                text
                            }
                            engagement_baseline {
                                text
                            }
                        }
                        items {
                            engagement_point_title {
                                text
                            }
                            engagement_point_icon
                            engagement_point_content {
                                html
                            }
                        }
                    }
                    ... on PrismicWhoAreWeBodyChiffres {
                        slice_type
                        primary {
                            id
                            chiffres_title {
                                text
                            }
                            chiffres_baseline {
                                text
                            }
                            chiffres_content {
                                html
                            }
                        }
                        items {
                            chiffres_kpi_title
                            chiffres_kpi_value
                        }
                    }
                    ... on PrismicWhoAreWeBodyTeam {
                        slice_type
                        primary {
                            id
                            title {
                                text
                            }
                        }
                        items {
                            people_name
                            job_title
                            fun_fact
                            people_picture {
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(
                                            transformOptions: { duotone: { highlight: "#FFFFFF", shadow: "#21294D" } }
                                            layout: CONSTRAINED
                                            width: 800
                                            height: 800
                                            placeholder: TRACED_SVG
                                        )
                                    }
                                }
                            }
                        }
                    }
                    ... on PrismicWhoAreWeBodyAlliance {
                        slice_type
                        primary {
                            id
                            alliance_title {
                                text
                            }
                            alliance_baseline {
                                text
                            }
                            alliance_content {
                                html
                            }
                        }
                        items {
                            alliance_enterprise_name {
                                text
                            }
                            alliance_enteprise_logo {
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(layout: CONSTRAINED, width: 800, height: 450, placeholder: TRACED_SVG)
                                    }
                                }
                            }
                            alliance_enterprise_website {
                                url
                            }
                        }
                    }
                }
                document_title {
                    text
                }
                document_description
            }
        }
    }
`

const Section = styled.section`
    padding: 4rem 0;
    position: relative;
    overflow: hidden;
`
const Baseline = styled(LeadParagraph)`
    text-transform: uppercase;
    margin-top: -1rem;
`

const TabNav = styled.nav`
    background: ${colors.beige50};
    backdrop-filter: blur(10px);
    position: fixed;
    z-index: 100;
    top: 3.5rem;
    left: 0;
    right: 0;
`
const Tabs = styled(Scrollspy)`
    list-style-type: none;
    display: flex;
    justify-content: flex-end;
    padding: 0.25rem 0 0;
    ${media.phone`
        display: none;
    `}
`
const Tab = styled.li`
    position: relative;
    margin-left: 0;

    &:not(:last-of-type) {
        margin-right: 1rem;
    }
    ${Title} {
        font-weight: 500;
    }
    & :after {
        content: '';
        transition: all 0.2s ease-in-out;
        display: block;
        opacity: 0;
        background: ${colors.darkBlue80};
        border-radius: 2px 2px 0 0;
        width: 100%;
        height: 2px;
        position: absolute;
        bottom: 0;
        left: 0;
    }
    &.active {
        & :after {
            opacity: 1;
        }
    }
    ${media.tablet`
        display: none;
    `}
`

const Mission = styled(Section)`
    position: relative;
`

const MissionBackground = styled.div`
    ${media.atLeastTablet`
        position: absolute;
        top: 50%;
        right: 1rem;
        transform: translateY(-50%);
        & ${ResponsiveImage} {
            width: 800px;
        }
    `}
`

const VisionImage = styled(ResponsiveImage)`
    ${media.tablet`
        position: absolute;
        width: 45vw;
        top: 0;
        right: 0;
        transform: translateY(-45%) translateX(10%);
    `}
`

const EngagementContainer = styled.div`
    padding: 2rem 0 1rem;
`

const EngagementTitle = styled(Title)`
    padding: 0;
`

const CircleIcon = styled.div`
    & > svg {
        background: ${colors.vividRed};
        padding: 0.3rem;
        border-radius: 50%;
    }
`

const Chiffres = styled(Section)`
    background-color: ${colors.vividRed};
    color: white;
`

const AllianceEntreprises = styled(Masonry)`
    display: flex;
    margin-left: -2rem;
    ${media.tablet`
        padding: 2rem 0;
    `}
    .column {
        padding-left: 2rem;
    }
    ${ResponsiveImage} {
        margin-bottom: 2rem;
        transition: all 0.2s ease-in-out;

        outline: 1px solid transparent;
        will-change: transform;
        -webkit-backface-visibility: hidden;
        -webkit-perspective: 1000;
        -webkit-transform-style: preserve-3d;

        &:hover {
            transform: scale3d(1.05, 1.05, 1.05);
            box-shadow: 0 0 40px -20px rgba(33, 41, 77, 0.8);
        }
        &:active {
            transform: scale3d(1, 1, 1);
            box-shadow: 0 0 0 0 ${colors.darkBlue80};
        }
    }
`

const KeyNumbers = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
`

const KeyNumber = styled.div`
    text-align: center;
    margin: 2rem;
`

const Value = styled(Title)`
    font-size: 6rem;
    font-weight: bolder;
`

const Legend = styled.div`
    font-weight: 700;
`

const TeamMatesGrid = styled(Masonry)`
    display: flex;
`

const TeamMateContainer = styled.figure`
    position: relative;
`

const Caption = styled(Title)`
    position: absolute;
    bottom: -1px;
    width: 100%;
    text-align: right;
    padding: 0.5rem;
    background-color: ${colors.white50};
    backdrop-filter: blur(10px);
`

const Alliance = styled(Section)`
    position: relative;
    overflow: hidden;

    :before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: calc(50% + 210px);
        right: 0;
        bottom: 0;
        background-image: linear-gradient(90deg, ${colors.beige} 0%, white 100%);
        border-radius: 1rem;
        transform: scaleY(0.7) skewX(1deg) rotate(-3deg) translateY(70px);
        ${media.tablet`
            display: none;
        `}
    }

    & ${Responsive} {
        position: relative;
    }

    & ${ResponsiveImage} =
`

export default QuiSommesNous
