import styled from 'styled-components'

const LeadParagraph = styled.p`
    font-size: 1.125rem;
    line-height: 1.875rem;
`

const Paragraph = styled.p`
    font-size: 1rem;
    line-height: 1.625rem;
`

export default Paragraph
export { Paragraph, LeadParagraph }
